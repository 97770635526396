$icon-font-path: "/assets/fonts/bootstrap/";
$brand-primary: #007bff;

$brand-info: #17a2b8;     // blue
$brand-success: #28a745;  // green
$brand-warning: #ffc107;  // yellow
$brand-danger: #dc3545;   // red


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api"; 
// $fa-font-path: "/assets/fonts/font-awesome/";
// @import "~font-awesome/scss/font-awesome";
@import "~ui-select/dist/select.css";

// @import url(http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,900|Roboto:300,500,700,900);

/**
 * App-wide Styles
 */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.row {
  margin-bottom: 5px;
}

@import "abgcandidate/abgcandidate";
@import "activity/activity";
@import "admin/admin";
@import "benchmark/attrsbenchmark/attrsbenchmark";
@import "benchmark/benchmark";
@import "benchmark/percentilebenchitem/percentilebenchitem";
@import "benchmark/percentilebenchmark/percentilebenchmark";
@import "bhiredcandidate/bhiredcandidate";
@import "bhiredcompetency/bhiredbenchmark/bhiredbenchmark";
@import "bhiredcompetency/bhiredcompetency";
@import "company/company";
@import "cultfactor/scatter/scatter";
@import "cultfactor/section/section";
@import "derailers/derailers";
@import "gamedata/gamedata";
@import "gamedata/gaptitude/gaptitude";
@import "gamedata/gbackgrounds/gbackgrounds";
@import "gamedata/gcharacters/gcharacters";
@import "gamedata/gminigames/gminigames";
@import "gamedata/gmusic/gmusic";
@import "gamedata/gquestions/gquestions";
@import "gamedata/gsequences/gsequences";
@import "jrt/jrt";
@import "keva/keva";
@import "kevaCandidate/kevaCandidate";
@import "list/list";
@import "listitem/listitem";
@import "main/main";
@import "managerialpot/managerialpot";
@import "motivation/motivation";
@import "mparam/mparam";
@import "personafactors/personafactors";
@import "personafactors/personafactors";
@import "psycho/psycho";
@import "reportspec/reportspec";
@import "score/score";
@import "sonyCandidate/sonyCandidate";
@import "successfactors/successfactors";
@import "successfactorupdate/successfactortagging/successfactortagging";
@import "successfactorupdate/successfactorupdate";
@import "successfactorupdate/successfactorvideos/successfactorvideos";
@import "version/version";
@import "../components/footer/footer";
@import "../components/modal/modal";
@import "../components/navbar/navbar";
@import "../components/oauth-buttons/oauth-buttons";
// Component styles are injected through gulp
/* inject:scss */

@import 'abgcandidate/abgcandidate';

@import 'activity/activity';

@import 'admin/admin';

@import 'benchmark/attrsbenchmark/attrsbenchmark';

@import 'benchmark/benchmark';

@import 'benchmark/percentilebenchitem/percentilebenchitem';

@import 'benchmark/percentilebenchmark/percentilebenchmark';

@import 'bhiredcandidate/bhiredcandidate';

@import 'bhiredcompetency/bhiredbenchmark/bhiredbenchmark';

@import 'bhiredcompetency/bhiredcompetency';

@import 'company/company';

@import 'cultfactor/scatter/scatter';

@import 'cultfactor/section/section';

@import 'derailers/derailers';

@import 'gamedata/gamedata';

@import 'gamedata/gaptitude/gaptitude';

@import 'gamedata/gbackgrounds/gbackgrounds';

@import 'gamedata/gcharacters/gcharacters';

@import 'gamedata/gminigames/gminigames';

@import 'gamedata/gmusic/gmusic';

@import 'gamedata/gorderingquestions/gorderingquestions';

@import 'gamedata/gquestions/gquestions';

@import 'gamedata/gsequences/gsequences';

@import 'gamedata/sequences/gsequences2';

@import 'gamedata/sjt/gsjt';

@import 'invite/invite';

@import 'jrt/jrt';

@import 'keva/keva';

@import 'kevaCandidate/kevaCandidate';

@import 'list/list';

@import 'listitem/listitem';

@import 'main/main';

@import 'managerialpot/managerialpot';

@import 'motivation/motivation';

@import 'mparam/mparam';

@import 'personafactors/personafactors';

@import 'personafactors/personafactors';

@import 'psycho/psycho';

@import 'reportspec/reportspec';

@import 'score/score';

@import 'sonyCandidate/sonyCandidate';

@import 'successfactors/successfactors';

@import 'successfactorupdate/successfactortagging/successfactortagging';

@import 'successfactorupdate/successfactorupdate';

@import 'successfactorupdate/successfactorvideos/successfactorvideos';

@import 'tvsreportspec/tvsreportspec';

@import 'version/version';

@import '../components/footer/footer';

@import '../components/modal/modal';

@import '../components/navbar/navbar';

@import '../components/oauth-buttons/oauth-buttons';

/* endinject */