.company_active_tab_bar {
  color: #555 !important;
  background-color: #e7e7e7;
}
.company_tab_bar_label {
  margin-left: 10px;
  cursor: pointer;
  float: left;
  padding: 15px;
  font-size: 18px;
  line-height: 20px;
  height: 50px;
  color: #777;
}
.company_tab_bar_label:hover {
  color: #555;
}

.bhired_tabs {
  width: 100%;
}

.keva_tabs {
  width: 100%;
}
.tvs_tabs {
  width: 100%;
}
.tvs_motors_private_tabs {
  width: 100%;
}
