.trash { color:rgb(209, 91, 71); }

.user-list {
	li {
		display: flex;
		border: none;
		border-bottom: 1px lightgray solid;
		margin-bottom: 0;
		&:last-child { border-bottom: none; }

		.user-info {
			flex-grow: 1;
		}
		.trash {
			display: flex;
			align-items: center;
			text-decoration: none;
		}
	}
}
